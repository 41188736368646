import { Injectable, inject } from '@angular/core';
import { of, catchError, finalize, map, switchMap, mergeMap, takeUntil, repeat, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadersFacade } from '@app/store/loader';
import { shippingAddressesActions } from '@app/store/shipping-addresses';
import { HttpService } from '../services/http.service';
import { favouritesActions } from '../actions/favourites.actions';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FavouritesEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    reload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddressSuccess),
            map(() => favouritesActions.getProducts({ background: false })),
        );
    });

    reloadInBackgroud$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                favouritesActions.setFavouriteProductSuccess,
                favouritesActions.setFavouriteProductError,
                favouritesActions.deleteFavouriteProductSuccess,
                favouritesActions.deleteFavouriteProductError,
            ),
            map(() => favouritesActions.getProducts({ background: true })),
        );
    });

    getFavourites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(favouritesActions.getProducts),
            switchMap(({ background }) => {
                if (background === false) {
                    this.loadersFacade.add('get-products');
                }
                return this.httpService.getProducts().pipe(
                    map(({ items }) => favouritesActions.getProductsSuccess({ items })),
                    catchError(() => of(favouritesActions.getProductsError())),
                    finalize(() => {
                        if (background === false) {
                            this.loadersFacade.delete('get-products');
                        }
                    }),
                );
            }),
            takeUntil(this.actions$.pipe(ofType(favouritesActions.setFavouriteProduct, favouritesActions.deleteFavouriteProduct))),
            repeat(),
        );
    });

    setFavouriteProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(favouritesActions.setFavouriteProduct),
            mergeMap((value) => {
                return this.httpService.setFavouriteProduct(value).pipe(
                    map(() => favouritesActions.setFavouriteProductSuccess()),
                    catchError(() => of(favouritesActions.setFavouriteProductError())),
                );
            }),
        );
    });

    setFavouriteProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(favouritesActions.setFavouriteProductSuccess),
                tap(() => {
                    this.messageService.add(this.translateService.instant('favourites.added-to-favourites'), 'success');
                }),
            );
        },
        { dispatch: false },
    );

    deleteFavouriteProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(favouritesActions.deleteFavouriteProductSuccess),
                tap(() => {
                    this.messageService.add(this.translateService.instant('favourites.removed-from-favourites'), 'success');
                }),
            );
        },
        { dispatch: false },
    );

    delteFavouriteProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(favouritesActions.deleteFavouriteProduct),
            mergeMap((value) => {
                return this.httpService.deleteFavouriteProduct(value).pipe(
                    map(() => favouritesActions.deleteFavouriteProductSuccess()),
                    catchError(() => of(favouritesActions.deleteFavouriteProductError())),
                );
            }),
        );
    });

    clear$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddress),
            map(() => favouritesActions.clearResults()),
        );
    });
}
