import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProductsValue } from '../models';
import { Product } from '@app/store/models';

export const productsActions = createActionGroup({
    source: 'Products',
    events: {
        'Get Products': props<{ params: ProductsValue }>(),
        'Get Products Params': emptyProps(),
        'Get Products Params Success': props<{ items: Product[]; totalItems: number; params: ProductsValue }>(),
        'Get Products Params Error': props<{ params: ProductsValue }>(),
        'Clear Results': emptyProps(),
        Clear: emptyProps(),
    },
});
