import { ProductDiscountDto } from './product-discount.dto';

export class ProductDiscount {
    minimumQuantity: number;
    price: number;
    discountPercentage?: number;

    constructor(dto: ProductDiscountDto) {
        this.minimumQuantity = dto.minimum_quantity_for_price_promo;
        this.price = Math.round(dto.item_price_uom * 100);
    }
}
