import { createReducer, on } from '@ngrx/store';
import { favouritesActions } from '../actions/favourites.actions';
import { Product } from '@app/store/models';

export interface FavouriteState {
    loaded: boolean | null;
    products: {
        id: string;
        no: string;
        item: Product | null;
    }[];
}

export const initialState: FavouriteState = {
    loaded: null,
    products: [],
};

export const favouritesReducer = createReducer(
    initialState,

    on(
        favouritesActions.getProductsSuccess,
        (state, { items }): FavouriteState => ({
            ...state,
            loaded: true,
            products: items.map((item) => ({
                id: item.id,
                no: item.no,
                item,
            })),
        }),
    ),

    on(
        favouritesActions.getProductsError,
        (state): FavouriteState => ({
            ...state,
            loaded: true,
        }),
    ),

    /**
     * Set Favourites
     */
    on(favouritesActions.setFavouriteProduct, (state, action): FavouriteState => {
        const products = [...state.products];
        const index = products.findIndex((product) => product.id === action.id && product.no === action.no);
        if (index === -1) {
            products.push({
                id: action.id,
                no: action.no,
                item: null,
            });
        }
        return {
            ...state,
            products,
        };
    }),

    /**
     * Delete Favourite Products
     */
    on(favouritesActions.deleteFavouriteProduct, (state, action): FavouriteState => {
        const products = [...state.products];
        const index = products.findIndex((product) => product.id === action.id && product.no === action.no);
        if (index !== -1) {
            products.splice(index, 1);
        }
        return {
            ...state,
            products,
        };
    }),

    /**
     * Clear
     */
    on(
        favouritesActions.clearResults,
        (state): FavouriteState => ({
            ...state,
            products: [],
        }),
    ),
    on(
        favouritesActions.clear,
        (): FavouriteState => ({
            ...initialState,
        }),
    ),
);
