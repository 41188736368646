import { Product } from '@app/store/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const favouritesActions = createActionGroup({
    source: 'Favourites',
    events: {
        'Get Products': props<{ background: boolean }>(),
        'Get Products Success': props<{ items: Product[] }>(),
        'Get Products Error': emptyProps(),

        'Set Favourite Product': props<{ id: string; no: string }>(),
        'Set Favourite Product Success': emptyProps(),
        'Set Favourite Product Error': emptyProps(),

        'Delete Favourite Product': props<{ id: string; no: string }>(),
        'Delete Favourite Product Success': emptyProps(),
        'Delete Favourite Product Error': emptyProps(),

        'Clear Results': emptyProps(),
        Clear: emptyProps(),
    },
});
