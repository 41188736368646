import { inject } from '@angular/core';
import { CanActivateFn, CanDeactivateFn } from '@angular/router';
import { ShoppingCartFacade } from '../facades/shopping-cart.facade';

export function shoppingCartCleanup<T>(): CanActivateFn | CanDeactivateFn<T> {
    return () => {
        const shoppingCartFacade = inject(ShoppingCartFacade);
        shoppingCartFacade.clear();
        return true;
    };
}
