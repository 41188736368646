import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiListingResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { FavouriteValue, FavouriteValueDto } from '../models';
import { Product, ProductDto } from '@app/store/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getProducts() {
        return this.httpClient
            .addAllSwitches()
            .get<ApiListingResponse<ProductDto[]>>(`api/v1/items/favorites/list`)
            .pipe(
                map((response) => ({
                    items: response.data.map((productDto) => new Product(productDto)),
                    totalItems: response.data.length,
                })),
            );
    }

    setFavouriteProduct(value: FavouriteValue) {
        const params = new FavouriteValueDto(value);

        return this.httpClient.addAllSwitches().put(`api/v1/items/favorites`, params);
    }

    deleteFavouriteProduct(value: FavouriteValue) {
        return this.httpClient.addAllSwitches().delete(`api/v1/items/favorites/${value.id}/${value.no}`);
    }
}
