import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShoppingCartState } from '../reducers/shopping-cart.reducer';
import { SHOPPING_CART_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ShoppingCartState>(SHOPPING_CART_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state: ShoppingCartState) => state.loaded);
const selectCartDraft = createSelector(selectState, (state: ShoppingCartState) => state.cartDraft);
const selectCartPending = createSelector(selectState, (state: ShoppingCartState) => state.cartPending);

/**
 * Selects Cart, Cart Draft & Cart Pending items - combine into one cart
 */
const selectCartPreview = createSelector(selectState, (state: ShoppingCartState) => {
    // cart items
    const cartPreview = state.cart.map((item) => ({
        id: item.id,
        no: item.no,
        qty: item.qty,
        unit: item.unit,
    }));

    // cart draft items
    state.cartDraft.forEach((preview) => {
        const index = cartPreview.findIndex((item) => item.id === preview.id && item.no === preview.no && item.unit === preview.unit);
        if (index === -1) {
            cartPreview.push({ ...preview });
        } else {
            cartPreview[index].qty = cartPreview[index].qty + preview.qty;
        }
    });

    // cart pending items
    state.cartPending.forEach((preview) => {
        const index = cartPreview.findIndex((item) => item.id === preview.id && item.no === preview.no && item.unit === preview.unit);
        if (index === -1) {
            cartPreview.push({ ...preview });
        } else {
            cartPreview[index].qty = cartPreview[index].qty + preview.qty;
        }
    });

    return cartPreview;
});

const selectCart = createSelector(selectCartPreview, selectState, (cartPreview, state) => {
    const cart = [...state.cart].filter((item) => {
        const qty = cartPreview.find((preview) => preview.no === item.no && preview.id === item.id && item.unit === preview.unit)?.qty || 0;
        return qty !== 0;
    });

    return cart;
});

const selectCartPreviewQty = createSelector(selectState, (state: ShoppingCartState) => {
    const cartQtys = state.cart.map((item) => item.qty);
    const cartDraftQtys = state.cartDraft.map((item) => item.qty);
    const cartPendingQtys = state.cartPending.map((item) => item.qty);
    return [cartQtys, cartDraftQtys, cartPendingQtys].flat().reduce((prev, next) => prev + next, 0);
});

const selectCartHasItems = createSelector(selectCartPreviewQty, (qty) => qty > 0);
const selectCartProcessing = createSelector(selectState, (state: ShoppingCartState) => state.cartDraft.length + state.cartPending.length > 0);

const selectCartItemProcessing = (id: string, no: string) =>
    createSelector(selectState, (state) => {
        return (
            state.cartDraft.findIndex((item) => item.id === id && item.no === no) !== -1 ||
            state.cartPending.findIndex((item) => item.id === id && item.no === no) !== -1
        );
    });

const selectCartItemInBasket = (id: string, no: string) =>
    createSelector(selectState, (state) => {
        return state.cart.findIndex((item) => item.id === id && item.no === no) !== -1;
    });

const selectCartItemQty = (id: string, no: string, unit: string) =>
    createSelector(selectState, (state) => {
        return state.cart.find((item) => item.id === id && item.no === no && item.unit === unit)?.qty || 0;
    });

const selectIsCartItemQtyExceeded = (id: string, no: string, unit: string) =>
    createSelector(selectState, (state) => {
        const availableQty = state.cart.find((item) => item.id === id && item.no === no && item.unit === unit)?.product.availability || 0;
        const addedQty = state.cart.find((item) => item.id === id && item.no === no && item.unit === unit)?.qty || 0;

        return availableQty < addedQty;
    });

const selectCartItemsWithoutQtyPerUnit = createSelector(selectState, (state) => state.cart.filter((item) => item.qtyPerUnit === null));

export {
    selectLoaded,
    selectCart,
    selectCartDraft,
    selectCartPending,
    selectCartPreview,
    selectCartPreviewQty,
    selectCartProcessing,
    selectCartItemProcessing,
    selectCartItemQty,
    selectCartItemInBasket,
    selectCartHasItems,
    selectCartItemsWithoutQtyPerUnit,
    selectIsCartItemQtyExceeded,
};
