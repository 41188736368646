import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ShoppingCartItem, ShoppingCartItemQtyPerUnit, ShoppingCartItemQtyPerUnitValue, ShoppingCartValue } from '../models';

export const shoppingCartActions = createActionGroup({
    source: 'Shopping Cart',
    events: {
        'Get Cart': emptyProps(),
        'Get Cart Success': props<{ cart: ShoppingCartItem[] }>(),
        'Get Cart Error': emptyProps(),

        'Update Cart Draft': props<{ value: ShoppingCartValue }>(),
        'Update Cart': props<{ value: ShoppingCartValue[] }>(),
        'Update Cart Success': props<{ cart: ShoppingCartItem[]; value: ShoppingCartValue[] }>(),
        'Update Cart Error': emptyProps(),

        'Get Product Qty Per Unit Success': props<{ items: ShoppingCartItemQtyPerUnit[] }>(),
        'Get Product Qty Per Unit Error': props<{ items: ShoppingCartItemQtyPerUnitValue[] }>(),

        Clear: emptyProps(),
    },
});
