import { createReducer, on } from '@ngrx/store';
import { shoppingCartActions } from '../actions/shopping-cart.actions';
import { ShoppingCartItem, ShoppingCartValue } from '../models';

export interface ShoppingCartState {
    loaded: boolean | null;
    cart: ShoppingCartItem[];
    cartDraft: ShoppingCartValue[];
    cartPending: ShoppingCartValue[];
}

export const initialState: ShoppingCartState = {
    loaded: null,
    cart: [],
    cartDraft: [],
    cartPending: [],
};

export const shoppingCartReducer = createReducer(
    initialState,
    /**
     * Get Cart
     */
    on(
        shoppingCartActions.getCart,
        (state): ShoppingCartState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(shoppingCartActions.getCartSuccess, (state, payload): ShoppingCartState => {
        const cart = [...payload.cart];

        state.cart.forEach((item) => {
            const index = cart.findIndex((cartItem) => cartItem.id === item.id && cartItem.no === item.no && cartItem.unit === item.unit);
            if (index !== -1) {
                cart.splice(index, 1, {
                    ...cart[index],
                    qtyPerUnit: item.qtyPerUnit,
                });
            }
        });

        return {
            ...state,
            loaded: true,
            cart,
        };
    }),
    on(
        shoppingCartActions.getCartError,
        (state): ShoppingCartState => ({
            ...state,
            loaded: true,
        }),
    ),

    /**
     * Update Cart Draft
     */
    on(shoppingCartActions.updateCartDraft, (state, { value }): ShoppingCartState => {
        const cartDraft = [...state.cartDraft];
        const cartDraftItemIndex = cartDraft.findIndex((item) => item.id === value.id && item.no === value.no);
        const cartDraftItem = { ...(cartDraftItemIndex !== -1 ? cartDraft[cartDraftItemIndex] : value) };

        // on old item - add qty
        if (cartDraftItemIndex !== -1) {
            cartDraftItem.qty = cartDraftItem.qty + value.qty;
        }

        // on 0 quantity - remove item, otherwise replace with now value
        if (cartDraftItemIndex !== -1) {
            if (cartDraftItem.qty === 0) {
                cartDraft.splice(cartDraftItemIndex, 1);
            } else {
                cartDraft.splice(cartDraftItemIndex, 1, cartDraftItem);
            }
        } else if (cartDraftItem.qty !== 0) {
            cartDraft.push(cartDraftItem);
        }

        return {
            ...state,
            cartDraft,
        };
    }),

    /**
     * Update Cart Draft
     */
    on(
        shoppingCartActions.updateCart,
        (state): ShoppingCartState => ({
            ...state,
            cartDraft: [],
            cartPending: [...state.cartDraft],
        }),
    ),
    on(shoppingCartActions.updateCartSuccess, (state, payload): ShoppingCartState => {
        const cart = [...payload.cart];

        state.cart.forEach((item) => {
            const index = cart.findIndex((cartItem) => cartItem.id === item.id && cartItem.no === item.no && cartItem.unit === item.unit);
            if (index !== -1) {
                cart.splice(index, 1, {
                    ...cart[index],
                    qtyPerUnit: item.qtyPerUnit,
                });
            }
        });

        return {
            ...state,
            cart,
            cartPending: [],
        };
    }),
    on(
        shoppingCartActions.updateCartError,
        (state): ShoppingCartState => ({
            ...state,
            cartPending: [],
        }),
    ),

    /**
     * Set QtyPerUnit after cart item change
     */
    on(shoppingCartActions.getProductQtyPerUnitSuccess, (state, { items }): ShoppingCartState => {
        const cart = [...state.cart];
        items.forEach((item) => {
            const index = cart.findIndex((cartItem) => cartItem.id === item.id && cartItem.no === item.no && cartItem.unit === item.unit);
            if (index !== -1) {
                cart.splice(index, 1, {
                    ...cart[index],
                    qtyPerUnit: item.qtyPerUnit,
                });
            }
        });

        return {
            ...state,
            cart,
        };
    }),

    on(
        shoppingCartActions.clear,
        (): ShoppingCartState => ({
            ...initialState,
        }),
    ),
);
