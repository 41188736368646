import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FAVOURITES_FEATURE_KEY } from './keys';
import { favouritesReducer } from './reducers/favourites.reducer';
import { FavouritesEffects } from './effects/favourites.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FAVOURITES_FEATURE_KEY, favouritesReducer), EffectsModule.forFeature([FavouritesEffects])],
})
export class FavouritesStoreModule {}
