import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import {
    ShoppingCartItem,
    ShoppingCartItemDto,
    ShoppingCartItemQtyPerUnit,
    ShoppingCartItemQtyPerUnitDto,
    ShoppingCartItemQtyPerUnitValue,
    ShoppingCartValue,
    ShoppingCartValueDto,
} from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getCart() {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ShoppingCartItemDto[]>>(`api/v1/carts`)
            .pipe(map((response) => response.data.map((item) => new ShoppingCartItem(item))));
    }

    updateCart(value: ShoppingCartValue[]) {
        const params = new ShoppingCartValueDto(value);

        return this.httpClient
            .addAllSwitches()
            .post<ApiResponse<ShoppingCartItemDto[]>>(`api/v1/carts`, params)
            .pipe(map((response) => response.data.map((item) => new ShoppingCartItem(item))));
    }

    getProductsQtyPerUnit(values: ShoppingCartItemQtyPerUnitValue[]) {
        return forkJoin(values.map((value) => this.getProductQtyPerUnit(value)));
    }

    private getProductQtyPerUnit(value: ShoppingCartItemQtyPerUnitValue) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ShoppingCartItemQtyPerUnitDto[]>>(`api/v1/uoms/items/${value.id}`)
            .pipe(
                map((response) => {
                    const qtyPerUnit = response.data.find((item) => item.code === value.unit)?.qty_per_unit_of_measure ?? 1;

                    return new ShoppingCartItemQtyPerUnit(value, qtyPerUnit);
                }),
            );
    }
}
