import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';
import { selectProductIsFavourite, selectProducts, selectProductsIds, selectTotalItems, selectLoaded } from '../selectors/favourites.selector';
import { favouritesActions } from '../actions/favourites.actions';
import { Product } from '@app/store/models';

@Injectable({
    providedIn: 'root',
})
export class FavouritesFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    totalItems$ = this.store.select(selectTotalItems).pipe(filter((data): data is number => data !== null));
    products$ = this.store.select(selectProducts).pipe(filter((data): data is Product[] => data !== null));
    productsIds$ = this.store.select(selectProductsIds);

    isFavourite$(id: string, no: string) {
        return this.store.select(selectProductIsFavourite(id, no));
    }

    isFavourite(id: string, no: string): boolean {
        let value = false;
        this.store
            .select(selectProductIsFavourite(id, no))
            .pipe(
                take(1),
                tap((isFavourite) => (value = isFavourite)),
            )
            .subscribe();
        return value;
    }

    getFavourites(): void {
        this.store.dispatch(favouritesActions.getProducts({ background: true }));
    }

    setFavouriteProduct(id: string, no: string): void {
        this.store.dispatch(favouritesActions.setFavouriteProduct({ id, no }));
    }

    deleteFavouriteProduct(id: string, no: string): void {
        this.store.dispatch(favouritesActions.deleteFavouriteProduct({ id, no }));
    }

    clear(): void {
        this.store.dispatch(favouritesActions.clear());
    }
}
