import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs';
import { ProductDiscountDto, DiscountData } from '../models';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getProductDiscounts(productNo: string, productUnit: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDiscountDto[]>>(`api/v1/items/threshold/${productNo}/${productUnit}`)
            .pipe(map(({ data }) => new DiscountData(data)));
    }
}
