import { ShoppingCartItemQtyPerUnitValue } from './shopping-cart-item-qty-per-unit.value';

export class ShoppingCartItemQtyPerUnit {
    id: string;
    no: string;
    unit: string;
    qtyPerUnit: number | null;
    constructor(shoppingCartItemQtyPerUnitValue: ShoppingCartItemQtyPerUnitValue, qtyPerUnit: number) {
        this.id = shoppingCartItemQtyPerUnitValue.id;
        this.no = shoppingCartItemQtyPerUnitValue.no;
        this.unit = shoppingCartItemQtyPerUnitValue.unit;
        this.qtyPerUnit = qtyPerUnit;
    }
}
