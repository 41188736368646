import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FAVOURITES_FEATURE_KEY } from '../keys';
import { FavouriteState } from '../reducers/favourites.reducer';
import { Product } from '@app/store/models';

const selectState = createFeatureSelector<FavouriteState>(FAVOURITES_FEATURE_KEY);
const selectLoaded = createSelector(selectState, (state) => state.loaded);
const selectProducts = createSelector(selectState, (state) => state.products.map((product) => product.item).filter((item): item is Product => item !== null));
const selectProductsIds = createSelector(selectState, (state) => state.products.map((product) => product.id));
const selectProductIsFavourite = (id: string, no: string) =>
    createSelector(selectState, (state) => state.products.findIndex((product) => product.id === id && product.no === no) !== -1);
const selectTotalItems = createSelector(selectProducts, (products) => products.length);

export { selectLoaded, selectProducts, selectProductsIds, selectProductIsFavourite, selectTotalItems };
