import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectLoaded, selectOldPrice, selectProductDiscounts } from '../selectors/product-discounts.selector';
import { ProductDiscount } from '../models';
import { productDiscountsActions } from '../actions/product-discounts.actions';

@Injectable({
    providedIn: 'root',
})
export class ProductDiscountsFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    productDiscounts$ = this.store.select(selectProductDiscounts).pipe(filter((data): data is ProductDiscount[] => data !== null));
    oldPrice$ = this.store.select(selectOldPrice);

    getProductDiscounts(productNo: string, productUnit: string): void {
        this.store.dispatch(productDiscountsActions.getProductDiscounts({ productNo, productUnit }));
    }

    clear(): void {
        this.store.dispatch(productDiscountsActions.clear());
    }
}
