import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SHOPPING_CART_FEATURE_KEY } from './keys';
import { shoppingCartReducer } from './reducers/shopping-cart.reducer';
import { ShoppingCartEffects } from './effects/shopping-cart.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(SHOPPING_CART_FEATURE_KEY, shoppingCartReducer), EffectsModule.forFeature(ShoppingCartEffects)],
})
export class ShoppingCartStoreModule {}
