import { FavouriteValue } from './favourite.value';

class FavouriteValueDto {
    item_id: string;
    item_no: string;
    constructor(data: FavouriteValue) {
        this.item_id = data.id;
        this.item_no = data.no;
    }
}

export { FavouriteValueDto };
