import { Product } from '../../models';
import { ShoppingCartItemDto } from './shopping-cart.model.dto';

export class ShoppingCartItem {
    id: string;
    no: string;
    qty: number;
    unit: string;
    price: number;
    qtyPerUnit: number | null;
    product: Product;
    constructor(shoppingCartDto: ShoppingCartItemDto) {
        this.id = shoppingCartDto.item_id;
        this.no = shoppingCartDto.item_no;
        this.qty = shoppingCartDto.qty;
        this.unit = shoppingCartDto.item_uom;
        this.price = Math.round(100 * shoppingCartDto.price_net);
        this.qtyPerUnit = null;
        this.product = new Product(shoppingCartDto.item);
    }
}
