import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { ShoppingCartFacade } from '../facades/shopping-cart.facade';

export function shoppingCartLoaded(): CanActivateFn {
    return () => {
        const shoppingCartFacade = inject(ShoppingCartFacade);

        return shoppingCartFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    shoppingCartFacade.getShoppingCart();
                }
            }),
            filter((loaded): loaded is boolean => loaded !== null),
            take(1),
        );
    };
}
