import { ShoppingCartValue } from './shopping-cart.value';

class ShoppingCartValueDto {
    items: {
        item_id: string;
        item_no: string;
        uom_code: string;
        count: number;
    }[];
    constructor(shoppingCartValue: ShoppingCartValue[]) {
        this.items = shoppingCartValue.map((item) => ({
            item_id: item.id,
            item_no: item.no,
            uom_code: item.unit,
            count: item.qty,
        }));
    }
}

export { ShoppingCartValueDto };
