import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { FavouritesFacade } from '../facades/favourites.facade';

export function favouritesLoaded(): CanActivateFn {
    return () => {
        const favouritesFacade = inject(FavouritesFacade);

        return favouritesFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    favouritesFacade.getFavourites();
                }
            }),
            filter((loaded): loaded is boolean => loaded !== null),
            take(1),
            map(() => true),
        );
    };
}
