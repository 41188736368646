import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { FavouritesFacade } from '../facades/favourites.facade';

export function favouritesCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const favouritesFacade = inject(FavouritesFacade);
        favouritesFacade.clear();
        return true;
    };
}
