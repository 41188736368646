import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectLoaded,
    selectCart,
    selectCartPreview,
    selectCartPreviewQty,
    selectCartItemProcessing,
    selectCartItemInBasket,
    selectCartProcessing,
    selectCartHasItems,
    selectCartItemQty,
    selectIsCartItemQtyExceeded,
} from '../selectors/shopping-cart.selector';
import { shoppingCartActions } from '../actions/shopping-cart.actions';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    cart$ = this.store.select(selectCart);
    cartPreview$ = this.store.select(selectCartPreview);
    cartPreviewQty$ = this.store.select(selectCartPreviewQty);
    cartProcessing$ = this.store.select(selectCartProcessing);
    cartHasItems$ = this.store.select(selectCartHasItems);

    cartItemProcessing$ = (id: string, no: string) => this.store.select(selectCartItemProcessing(id, no));
    cartItemInBasket$ = (id: string, no: string) => this.store.select(selectCartItemInBasket(id, no));
    cartItemQty$ = (id: string, no: string, code: string) => this.store.select(selectCartItemQty(id, no, code));
    catItemProcessing$ = (id: string, no: string) => this.store.select(selectCartItemProcessing(id, no));
    isCartItemQtyExceeded$ = (id: string, no: string, unit: string) => this.store.select(selectIsCartItemQtyExceeded(id, no, unit));

    getShoppingCart(): void {
        this.store.dispatch(shoppingCartActions.getCart());
    }

    updateShoppingCart(id: string, no: string, unit: string, qty: number): void {
        this.store.dispatch(shoppingCartActions.updateCartDraft({ value: { id, no, unit, qty } }));
    }

    clear(): void {
        this.store.dispatch(shoppingCartActions.clear());
    }
}
